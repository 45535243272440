var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"tag":"section","fluid":""}},[_c('base-material-card',{attrs:{"icon":"mdi-alarm-multiple","title":("Wettkämpfe " + _vm.lname),"color":"red"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Filter"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),(!_vm.$vuetify.breakpoint.mdAndDown)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-range-slider',{attrs:{"tick-labels":_vm.dates_format,"value":_vm.dates,"min":"0","max":_vm.dates.length - 1,"ticks":"always","tick-size":"4"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Wettkämpfe anzeigen von","value":_vm.dates_format[_vm.date[0]],"items":_vm.dates_format},on:{"change":function (a) { return _vm.set(0, a); }}})],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Wettkämpfe anzeigen bis","value":_vm.dates_format[_vm.date[1]],"items":_vm.dates_format},on:{"change":function (a) { return _vm.set(1, a); }}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Klicken Sie auf eine Begegnung um die Live-Anzeige bzw. das Endergebnis anzuzeigen.")])])],1),_c('v-data-table',{attrs:{"items":_vm.events_filtered,"headers":[
        { text: 'Beginn', value: 'startDate', sortable: false },
        { text: 'Liga', value: 'league.shortName', sortable: false },
        { text: 'Mannschaften', value: 'mannschaften', sortable: false },
        { text: 'Punkte', value: 'final', sortable: false, align: 'center' },
        { text: 'TP', value: 'tp', sortable: false, align: 'center' },
        { text: 'Hinweise', value: 'publicComment', sortable: false, align: 'center' },
        { text: 'Halle', value: 'venue', sortable: false, align: 'center' }
      ],"search":_vm.filter,"mobile-breakpoint":0,"items-per-page":50},on:{"click:row":_vm.open},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
      var item = ref.item;
return [(item.status === 'EventPostponed')?_c('i',[_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(_vm._f("dateformat")(item.startDate,'DD.MM.YYYY')))]),_c('br'),_vm._v("wird verschoben,"),_c('br'),_vm._v("Termin noch offen")]):_c('span',{domProps:{"innerHTML":_vm._f("dateformat")(item.startDate,'dddd<br>DD.MM.YYYY<br>HH:mm')}})]}},{key:"item.mannschaften",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"none","white-space":"nowrap"},domProps:{"innerHTML":_vm._f("nl2br")(item.mannschaften.join('\n'))}})]}},{key:"item.final",fn:function(ref){
      var item = ref.item;
return _vm._l((item.teams),function(e,i){return _c('div',{key:((item._id) + "-" + i)},[(e.result !== null && e.result !== undefined)?[_vm._v(_vm._s(_vm._f("float2_0")(e.result.final)))]:_vm._e()],2)})}},{key:"item.tp",fn:function(ref){
      var item = ref.item;
return _vm._l((item.teams),function(e,i){return _c('div',{key:((item._id) + "-" + i)},[_vm._v(" "+_vm._s(e.result.tp)+" ")])})}},{key:"item.publicComment",fn:function(ref){
      var item = ref.item;
return [(item.publicComment)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","text":""}},on),[_c('v-icon',[_vm._v("far fa-info-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.publicComment)+" ")])]):_vm._e()]}},{key:"item.venue",fn:function(ref){
      var item = ref.item;
return [(item.venue)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.openmaps(item.venue.address)}}},on),[_c('v-icon',[_vm._v("far fa-circle-h")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.venue.name)),_c('br'),_vm._v(" "+_vm._s(item.venue.address.streetAddress)+" "+_vm._s(item.venue.address.streetNumber)),_c('br'),_vm._v(" "+_vm._s(item.venue.address.postalCode)+" "+_vm._s(item.venue.address.addressLocality)+" ")])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }